<template>
	<ModalTemplate v-model="isShow" 
		name="slot-create" 
		:closer="closeHandler"
		queryName="m_cs"
		transition="create">
		<div class="slot-create-page" v-if="isCalendarPage && userHaveAccess">
			<SlotShortSettings v-if="calendarIsDefault || !calendarIsCommercial"
				:isModal="true"
				:submitHandler="submitHandler"
				:closeHandler="closeModal"/>
			<SlotSettings v-else 				
				:isModal="true"
				:submitHandler="submitHandler" 
				:closeHandler="closeModal"/>
		</div>
	</ModalTemplate>
</template>

<script>
import { globalBus } from '../../../GlobalEventBus';
import ModalTemplate from '../ModalTemplate.vue';
import { 
	ADMIN_MEMBERSHIP_LEVEL, 
	MANAGER_MEMBERSHIP_LEVEL, 
	OWNER_MEMBERSHIP_LEVEL 
} from '../../../scripts/membershipLevels';
import ModalProvider from '../../../scripts/modalProvider';
import Slot from '../../../scripts/slot';

export default {
	name: 'CreateSlotModal',
	components: {
		ModalTemplate,
		SlotSettings: () => import("../../forms/SlotSettings.vue"),
		SlotShortSettings: () => import("../../forms/SlotShortSettings.vue"),
	},
	props: {
		active:{
			type: Boolean,
			default: false
		}
	},
	computed: {
		calendar(){
			return this.$store.getters.currentCalendar()
		},

		calendarUid(){
			return this.$route.params.calendarUid
		},
		calendarTimezone(){
			if (!this.calendar)
				return undefined
			return this.$store.getters.calendarTimezone
		},
		calendarIsCommercial(){
			if (!this.calendar)
				return false
			return this.calendar.IsCommercial
		},

		calendarIsDefault(){
			if (!this.calendar)
				return false
			if (this.$route.name != 'calendar')
				return false
			let defaultUserCalendar = this.$store.getters.getDefaultCalendar
			let currentCalendar = calendar.uid
			return defaultUserCalendar == currentCalendar
		},
		browserTimezone(){
            return this.$store.getters.browserTimezone
		},
		isCalendarPage(){
			return this.$route.name == "calendar-schedule"
		},
		userHaveAccess(){
			if (!this.calendar)
				return false
			return this.calendar.isEditable
		}
	},

	created() {
		globalBus.$on('slot-create', 'show', (value) => {
			if (!this.userHaveAccess)
				return
			this.showHandler()
		})
	},

	mounted() {
		if (this.active && this.userHaveAccess){
			const disableTransition = true
			this.showHandler(disableTransition)
		}
	},

	data() {
		return {
			isShow: false,
			isWaitResponse: false,
		};
	},

	watch: {
		isShow(value){
			if (!value){
				this.closeHandler()
			}
		},
		userHaveAccess(value){
			if (this.active && this.userHaveAccess){
				const disableTransition = true
				this.showHandler(disableTransition)
			}
		}
	},

	methods: {
		closeModal(){
			ModalProvider.back()
		},
		closeHandler(){
			this.withTransition("80dvh", 0.45)
			.then(() => {
				globalBus.$emit("static-menu", "update-visible", {
					visible: true,
				})
				this.isShow = false
			})
		},
		showHandler(disableTransition = false){
			globalBus.$emit("static-menu", "update-visible", {
				visible: false
			})
			setTimeout(() => {
				this.isShow = true
				this.withTransition("0", 0.6, disableTransition)
			}, 300)
		},
		submitHandler(form){
			if (!form || this.isWaitResponse)
				return

            let browserTimezone = this.$store.getters.browserTimezone

            // 1. Basic validation
            let isValid = form.validate()
            if (!isValid) return 

            // 2. Date validation with difference between timezones
            isValid = form.validateConvertedDate(browserTimezone)
            if (!isValid) return

            // 3. Get form data to submit
            let slotData = form.toSubmitData()
            let calendarUid = this.calendarUid
			this.isWaitResponse = true
			this.$store.dispatch('create-slot', {calendarUid, slotData})
			.then((slots) => {
				if (form.isCreateTemplate){
					this.$store.dispatch('setup-slot-templates')
				}
				if (this.$route.name == 'calendar-schedule') {
					globalBus.$emit(`calendar-schedule-${calendarUid}`, 'add-slot', slots)
				}
				this.closeHandler()
			}).finally(() => {
				this.isWaitResponse = false
			})
		},
		withTransition(position, seconds, disabledTransition = false){
			return new Promise((resolve, _) => {
				setTimeout(()=> {
					const modal = this.$el.querySelector(".slot-create-page")
					if (!disabledTransition)
						modal.style.transition = `top ${seconds}s cubic-bezier(0.22, 0.61, 0.36, 1)`
					modal.style.top = `${position}`
					setTimeout(() => {
						modal.style.transition = ""
						resolve()
					}, seconds*1000)
				}, 0)
			})
		}
	},
};
</script>

<style>

</style>

<style scoped>
.slot-create-page{
	position: fixed;
    margin-top: 32px;
	left: 0;
	right: 0;
	background: white;
	z-index: 4;
	top: 80dvh;
	bottom: 0;
	/* animation: create-slot-modal 0.6s cubic-bezier(0.22, 0.61, 0.36, 1); */
}

/* .create-enter-active{
	animation: create-slot-modal 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.create-leave-active{
	animation: create-slot-modal 0.6s cubic-bezier(0.22, 0.61, 0.36, 1) reverse;
} */

@keyframes create-slot-modal {
    from {
        top: 80dvh;
    }
    to{
        top: 0;
    }   
}
</style>